"use client"

import { CookieValueTypes, getCookie } from "cookies-next";
import { JwtPayload, jwtDecode } from "jwt-decode";
import { Dispatch, createContext, useCallback, useContext, useEffect, useReducer, useRef, useState } from "react";
import { envConfig } from "src/lib/client/envConfig";
import { useLocationContext } from "src/lib/contexts/LocationContext";
import { IUserInfo } from "src/lib/filmwebid/IUserInfo";
import useUserApi, { FetcherContextState } from "src/lib/filmwebid/useUserApi";
import IUser from "src/lib/types/filmwebid/User";

const COOKIE_NAME = envConfig.NEXT_PUBLIC_FILMWEBID_COOKIE_NAME;

export type UserContextType = {
	isAuthenticated: boolean;
	doLogin: () => void;
	doLogout: () => void;
	refreshAccessToken: () => Promise<boolean>;
	isMe: (userId: string) => boolean;
	getUserId: () => string | undefined;
	isExpired: () => boolean;
	getAuthHeader: () => string;

	watchlistedList: IWatchlistItem[];

	updateWatchlistIdList: (item: IWatchlistItem, remove?: boolean) => void;

	userProfile: IUser | null;
	refreshUserProfile: () => void;

	tmpUserImage: string | null;

	updateTmpUserImage: (b64Img: string) => void;
};

const UserContext = createContext<UserContextType>({
	isAuthenticated: false,
	doLogin: () => { },
	doLogout: () => { },
	refreshAccessToken: async () => false,
	isMe: userId => false,
	isExpired: () => true,
	getUserId,
	getAuthHeader: () => "",

	watchlistedList: [],
	updateWatchlistIdList: (item, remove = false) => { },
	userProfile: null,
	refreshUserProfile: () => { },
	tmpUserImage: null,

	updateTmpUserImage: (b64Img) => { }
});

interface IWatchlistItem {
	edi?: string | null;
	streamingId?: number | null;
}

interface IUserDataState {
	userInfo?: IUserInfo;
	watchlistedIdList: IWatchlistItem[];
	userProfile: IUser | null;
	tmpUserImage: string | null; // Base64
	profileFetchedAt: Date;
}

const USERDATA_INITIAL_STATE: IUserDataState = {
	watchlistedIdList: [],
	userProfile: null,
	tmpUserImage: null,
	profileFetchedAt: new Date()
};

//#region [Props]
type UserWrapperProps = {
	children: React.ReactNode;
};
//#endregion

//#region [Component]
export default function UserWrapper({ children }: UserWrapperProps) {
	const locationContext = useLocationContext();
	const fetcher = useUserApi();
	const refreshPromise = useRef<Promise<boolean> | null>(null);

	const [_isAuthenticated, setIsAuthenticated] = useState<boolean>(() => {
		return !!getCookie(COOKIE_NAME);
	});

	const [_userDataState, userDataDispatch] = useReducer(userDataReducer, USERDATA_INITIAL_STATE);

	const _refreshAccessToken = useCallback(async () => {
		let result = null;
		if (refreshPromise.current) {
			result = await refreshPromise.current;
		} else {
			refreshPromise.current = refreshAccessToken();
			result = await refreshPromise.current;
		}
		refreshPromise.current = null;
		setIsAuthenticated(!!result);
		return result;
	}, []);

	const _isMe = useCallback((userId: string) => {
		const uId = getUserId();
		if (!userId?.trim() || !uId) {
			return false;
		}
		return userId?.trim()?.toUpperCase() === uId?.toUpperCase();
	}, []);

	//---------- Sideeffects
	useEffect(() => {
		// fetch a user's profile
		if (_isAuthenticated) {
			getProfile(refreshAccessToken, fetcher, userDataDispatch);
		} else if (_userDataState.userProfile) {
			userDataDispatch({ type: "PROF_SET", userProfile: null })
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [_isAuthenticated, _userDataState.profileFetchedAt]);

	useEffect(() => {
		// every time the location is updated in the browser, update the database
		if (_isAuthenticated) {
			return handlePreferredLocation(refreshAccessToken, locationContext.location, fetcher);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [locationContext.location, _isAuthenticated]);

	useEffect(() => {
		// Fetch user's watchlist. Should ONLY be run if _isAuthenticated === true
		if (_isAuthenticated) {
			return getUserWatchlist(userDataDispatch, _refreshAccessToken, fetcher);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [_isAuthenticated]);

	return <UserContext.Provider value={{
		isAuthenticated: _isAuthenticated,
		doLogin: doLogin,
		doLogout: doLogout,
		refreshAccessToken: _refreshAccessToken,
		isMe: _isMe,
		isExpired: isExpired,
		getUserId: getUserId,

		getAuthHeader: getAuthHeader,

		watchlistedList: _userDataState.watchlistedIdList,
		updateWatchlistIdList: (item, remove = false) => userDataDispatch({ type: remove ? 'WL_REMOVE' : 'WL_ADD', item }),
		userProfile: _userDataState.userProfile,
		refreshUserProfile: () => userDataDispatch({ type: "REFRESH_PROFILE" }),
		tmpUserImage: _userDataState.tmpUserImage,
		updateTmpUserImage: (b64Img) => userDataDispatch({ type: "TMPIMG_SET", img: b64Img })
	}}>
		{children}
	</UserContext.Provider>
}
//#endregion

//#region [Other]
export function useUserContext() {
	return useContext(UserContext);
}
//#endregion

//#region [Other] helper functions
function doLogin() {
	if (typeof window !== "undefined") {
		const url = buildAuthorizeUrl();
		window.location.href = url;
	}
}
function buildAuthorizeUrl() {
	const redirectUri = encodeURIComponent(window.location.href);
	const clientId = envConfig.NEXT_PUBLIC_FILMWEBID_CLIENT_ID;
	const responseType = "token";
	const responseMode = "cookie";
	return `${envConfig.NEXT_PUBLIC_AUTH_BASE}/Authorize?client_id=${clientId}&response_type=${responseType}&response_mode=${responseMode}&redirect_uri=${redirectUri}`;
}

function doLogout() {
	// LATER: Har diskutert med Claus og vi skal bare sende en bit av auth token som en ekstra sjekk for å ikke eksponene hele tokenet på url'en
	const cookieVal = getCookie(COOKIE_NAME);
	const access_token = getFilmwebIdCookieValue(cookieVal, "accesstoken");
	const id = getFilmwebIdCookieValue(cookieVal, "id");
	if (typeof window !== "undefined") {
		window.location.href = `${envConfig.NEXT_PUBLIC_AUTH_BASE}/api/Identity/SignOut?returnUrl=${encodeURIComponent("https://" + window.location.host)}`
	}
}

function getFilmwebIdCookieValue(cookie: CookieValueTypes, name: string): string {
	if (cookie) {
		const cookieVal = decodeURIComponent(cookie as string);

		var ca = cookieVal.split('&');
		const _name = `${name}=`;
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i]?.trim();
			if (c.indexOf(_name) === 0) {
				return c.substring(_name.length, c.length);
			}
		}
	}
	return "";
}

async function refreshAccessToken(): Promise<boolean> {
	const cookieVal = getCookie(COOKIE_NAME);
	if (!cookieVal) {
		console.warn("No filmweb id cookie found. User is not authenticated");
		return false;
	}
	const access_token = getFilmwebIdCookieValue(cookieVal, "accesstoken");
	const id = getFilmwebIdCookieValue(cookieVal, "id");
	//console.debug("AuthContext: refreshAccessToken", id);
	// NOTE: We cannot request a new access token and have the server set the cookie, since we cannot
	// detect when the server sets or updates cookies
	try {
		const response = await fetch(`${envConfig.NEXT_PUBLIC_AUTH_BASE}/RefreshAccessToken`, {
			method: 'POST',
			mode: 'cors',
			cache: 'no-cache',
			credentials: 'include',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ accessToken: access_token, id: id, returnCookie: true })
		});
		//console.debug("AuthContext: refreshAccessToken response", response.status);
		if (!response || response.status === 410) {// Gone
			if (typeof window !== "undefined") {
				window.location.href = "/";
			}
		} else if (!response.ok) {
			doLogin();
		} else {
			return response.ok;
		}
	} catch (e) {
		console.error(e);
	}
	return false;
}

function getUserId() {
	const cookieVal = getCookie(COOKIE_NAME);
	return _getDecodedAccessToken(cookieVal)?.sub;
}

function _getDecodedAccessToken(cookie: CookieValueTypes): JwtPayload | null {
	const access_token = getFilmwebIdCookieValue(cookie, "accesstoken");
	if (access_token) {
		const decoded = jwtDecode<JwtPayload>(access_token);
		return decoded;
	}
	return null;
}

function isExpired(): boolean {
	const cookieVal = getCookie(COOKIE_NAME);
	var expiresAt = getExpiresAt(cookieVal);

	if (expiresAt) {
		const isExpired = new Date().getTime() >= expiresAt.getTime();
		//console.debug("isExpired", expiresAt, isExpired);
		return isExpired;
	}
	//console.debug("isExpired", expiresAt, true);
	return true;
}

function getExpiresAt(cookie: CookieValueTypes): Date | null {
	const access_token = _getDecodedAccessToken(cookie);
	//console.debug("cookie", cookie);
	//console.debug("accesstoken", access_token);
	if (access_token && access_token.exp) {
		return new Date(access_token.exp * 1000);
	}
	return null;
}

function getAuthHeader() {
	const access_token = getCookie(COOKIE_NAME);
	return `Bearer ${access_token}`;
}

function getProfile(refreshAccessToken: UserContextType["refreshAccessToken"], fetcher: ReturnType<typeof useUserApi>, userDataDispatch: Dispatch<UserAction>) {
	let abortController: AbortController | null = new AbortController();
	const _action = async () => {
		try {
			const contextState: FetcherContextState = {
				isAuthenticated: true,
				doLogin,
				getAuthHeader,
				refreshAccessToken
			};
			const result = await fetcher("/api/User/GetPrivateUserProfile", { method: "GET", signal: abortController!.signal, allowAnon: false, userContextState: contextState, skipRefreshToken: true });
			if (result) {
				userDataDispatch({ type: 'PROF_SET', userProfile: result });
				/* After discussions with Nils, the preferred location is only used for alerting, and should not update the cookie
				if (result.preferredLocation) {
					locationContext.setLocation(result.preferredLocation);
				}*/
			}
		} catch (err) {
			console.error(err);
		}
		abortController = null;

	};
	_action();
	return () => {
		if (abortController) {
			abortController.abort("");
		}
	}
}

function handlePreferredLocation(refreshAccessToken: UserContextType["refreshAccessToken"], location: string | null, fetcher: ReturnType<typeof useUserApi>) {
	let abortController = new AbortController();

	const contextState = {
		isAuthenticated: true,
		doLogin,
		getAuthHeader,
		refreshAccessToken
	};
	if (location) {
		// we have a location, push it to the database
		// fire and forget
		fetcher("/api/User/SavePreferredLocation", { method: "POST", body: location, signal: abortController.signal, allowAnon: false, userContextState: contextState });
	}

	return () => {
		if (abortController) {
			abortController.abort("");
		}
	};
}

function getUserWatchlist(userDataDispatch: Dispatch<UserAction>, refreshAccessToken: UserContextType["refreshAccessToken"], fetcher: ReturnType<typeof useUserApi>) {
	let abortController: AbortController | null = new AbortController();
	const _action = async () => {
		try {
			const contextState = {
				isAuthenticated: true,
				doLogin,
				getAuthHeader,
				refreshAccessToken
			};
			const result = await fetcher("/api/Watchlist", { method: "GET", signal: abortController!.signal, allowAnon: false, userContextState: contextState });
			userDataDispatch({ type: 'WL_REPLACE', idList: result?.watchlist ?? [] });
		} catch (err) {
			console.error(err);
		}
		abortController = null;
	};
	_action();
	return () => {
		if (abortController) {
			abortController.abort("");
		}
	}
}
//#endregion

//#region [Other] reducer
export type UserAction = IWatchlistAddAction |
	IWatchlistRemoveAction |
	IWatchlistReplaceAction |
	IWatchlistProfileSetAction |
	IWatchlistTmpImgSetAction |
	IWatchlistRefreshProfileAction |
	IUserInfoSetAction;



export interface IWatchlistAddAction {
	type: "WL_ADD";
	item: IWatchlistItem;
}

export interface IWatchlistRemoveAction {
	type: "WL_REMOVE";
	item: IWatchlistItem;
}

export interface IWatchlistReplaceAction {
	type: "WL_REPLACE";
	idList: IWatchlistItem[];
}

export interface IWatchlistProfileSetAction {
	type: "PROF_SET";
	userProfile: IUser | null;
}

export interface IWatchlistTmpImgSetAction {
	type: "TMPIMG_SET";
	img: string;
}

export interface IWatchlistRefreshProfileAction {
	type: "REFRESH_PROFILE";
}
export interface IUserInfoSetAction {
	type: "USERINFO_SET";
	userInfo: IUserInfo | undefined
}

function userDataReducer(state: IUserDataState, action: UserAction) {
	const newState = { ...state };

	switch (action.type) {
		case 'WL_ADD':
			const itemTesterAdd = (elem: IWatchlistItem) => (elem.edi && action.item.edi && elem.edi === action.item.edi) || (elem.streamingId && action.item.streamingId && elem.streamingId === action.item.streamingId);
			if (state.watchlistedIdList.some(itemTesterAdd)) {
				// no need to add, it is already there
				return state;
			} else {
				newState.watchlistedIdList = state.watchlistedIdList.concat(action.item);
			}
			break;
		case 'WL_REMOVE':
			const itemTesterRemove = (elem: IWatchlistItem) => (elem.edi && action.item.edi && elem.edi === action.item.edi) || (elem.streamingId && action.item.streamingId && elem.streamingId === action.item.streamingId);
			// not using filter here since the list might be very large and I don't want to update the state unless the item exists, and I want to stop on the first found element
			const idx = state.watchlistedIdList.findIndex(itemTesterRemove);
			if (idx === -1) {
				return state;
			}
			// cut out the element
			const newList = [...state.watchlistedIdList];
			newList.splice(idx, 1); // splice modifies array in-place
			newState.watchlistedIdList = newList;
			break;
		case 'WL_REPLACE':
			if (newState.watchlistedIdList.length === 0 && action.idList.length === 0) {
				return state; // nothing to update
			}
			newState.watchlistedIdList = action.idList;
			break;
		case 'PROF_SET':
			newState.userProfile = action.userProfile;
			break;
		case 'TMPIMG_SET':
			newState.tmpUserImage = action.img;
			break;
		case 'REFRESH_PROFILE':
			newState.profileFetchedAt = new Date();
			break;
		case 'USERINFO_SET':
			newState.userInfo = action.userInfo;
		default:
			// only used on invalid action type
			return state;
	}
	return newState;
}
//#endregion